<template>
  <div class="card-deposit-bonus">
    <div v-if="imageSrc" class="card-deposit-bonus__image">
      <atomic-picture :src="imageSrc" notLazy />
    </div>

    <div class="card-deposit-bonus__container">
      <div class="card-deposit-bonus__content">
        <div class="card-deposit-bonus__title">{{ bonusName }}</div>
        <div class="card-deposit-bonus__value">{{ bonusValue }}</div>
      </div>

      <div v-if="!hideButton" class="card-deposit-bonus__actions">
        <div v-if="props.unavailable" class="card-deposit-bonus__available">
          <div
            v-if="availableDepositLabel"
            class="card-deposit-bonus__available-deposit"
            v-html="availableDepositLabel"
          />

          <div v-else-if="availableAt" class="card-deposit-bonus__available-timer">
            {{
              getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel')
            }}

            <bonuses-timer :expiredAt="availableAt" size="sm" @timeUp="bonusStore.getDepositBonuses()" />
          </div>
        </div>

        <button-base v-else class="card-deposit-bonus__actions-deposit" type="primary" size="sm" @click="getDeposit">
          {{
            getContent(
              globalComponentsContent,
              defaultLocaleGlobalComponentsContent,
              'bonuses.depositBonus.depositButton'
            )
          }}
        </button-base>

        <button-base class="card-deposit-bonus__actions-info" type="secondary-2" size="sm" @click="openInfo">
          <atomic-icon id="info" />
        </button-base>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IBonus } from '~/core/types';

  const props = defineProps<{
    bonusInfo: IBonus[];
    hideButton?: boolean;
    unavailable?: boolean;
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent, formatAmount, getEquivalentFromBase, getMinBonusDeposit, replaceContent } = useProjectMethods();
  const walletStore = useWalletStore();
  const { activeAccount, successDepositCount } = storeToRefs(walletStore);
  const imagesContent: { bonusId: string; image: string }[] = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.bonuses?.depositBonus?.bonusImages
    : globalComponentsContent?.bonuses?.depositBonus?.bonusImages || [];

  const defaultImageContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent?.bonuses?.depositBonus?.defaultImage
    : globalComponentsContent?.bonuses?.depositBonus?.defaultImage;

  const bonusName = computed(() => {
    return props.bonusInfo[0].package?.name || props.bonusInfo[0].name;
  });

  const imageSrc = computed(() => {
    const findImage = imagesContent.find(imageData => imageData.bonusId === props.bonusInfo[0].id);
    return findImage?.image || defaultImageContent;
  });

  const getCahBonusValue = (bonusInfo: IBonus): string => {
    const amountItems = bonusInfo.assignConditions?.amountItems;
    const amountBase = bonusInfo.assignConditions?.baseCurrencyAmount;
    const exclusionItem = amountItems?.find(item => item.currency === activeAccount.value?.currency);
    if (exclusionItem) return formatAmount(exclusionItem.currency, exclusionItem.amount);
    if (amountBase) {
      const { amount, currency } = getEquivalentFromBase(amountBase, activeAccount.value?.currency);
      return formatAmount(currency, amount);
    }

    return formatAmount(activeAccount.value?.currency, 0);
  };

  const getPercentageBonusValue = (bonusInfo: IBonus): string => {
    const maxAmountItems = bonusInfo.assignConditions?.maxAmountItems;
    const maxAmountBase = bonusInfo.assignConditions?.baseCurrencyMaxAmount;
    const exclusionItem = maxAmountItems?.find(item => item.currency === activeAccount.value?.currency);
    let maxSum: string = '';

    if (exclusionItem) {
      maxSum = formatAmount(exclusionItem.currency, exclusionItem.amount, { withoutCents: true });
    } else if (maxAmountBase) {
      const { amount, currency } = getEquivalentFromBase(maxAmountBase, activeAccount.value?.currency);
      maxSum = formatAmount(currency, amount, { withoutCents: true });
    }

    const bonusPercentage = bonusInfo.assignConditions?.depositPercentage || 0;

    if (maxSum) {
      const separatorContent = getContent(
        globalComponentsContent,
        defaultLocaleGlobalComponentsContent,
        'bonuses.upToLabel'
      );
      return `${bonusPercentage}% ${separatorContent} ${maxSum}`;
    }

    return `${bonusPercentage}%`;
  };

  const bonusValue = computed(() => {
    let percentageBonusValue: string = '';
    let cashBonusValue: string = '';
    let freeSpinsCount: number = 0;
    let finalValue = '';

    props.bonusInfo.forEach(bonus => {
      if (bonus.type === 1 && !cashBonusValue) {
        cashBonusValue = getCahBonusValue(bonus);
      } else if (bonus.type === 2 && !percentageBonusValue) {
        percentageBonusValue = getPercentageBonusValue(bonus);
      } else if (bonus.type === 3) {
        freeSpinsCount += bonus.assignConditions?.presets?.[0]?.quantity || 0;
      }
    });

    if (percentageBonusValue) finalValue = percentageBonusValue;
    if (cashBonusValue) finalValue += finalValue ? ` + ${cashBonusValue}` : cashBonusValue;
    if (freeSpinsCount > 0) {
      const fsLabel = getContent(
        globalComponentsContent,
        defaultLocaleGlobalComponentsContent,
        'bonuses.freeSpin.label'
      );
      finalValue += finalValue ? ` + ${freeSpinsCount} ${fsLabel}` : `${freeSpinsCount} ${fsLabel}`;
    }

    return finalValue;
  });

  const bonusStore = useBonusStore();
  const { openWalletModal, openModal } = useModalStore();
  const { walletDepositBonus } = storeToRefs(bonusStore);
  const getDeposit = async (): Promise<void> => {
    const minDeposit = getMinBonusDeposit(props.bonusInfo[0]);
    walletDepositBonus.value = {
      id: props.bonusInfo[0].id,
      packageId: props.bonusInfo[0].package?.id,
      amount: minDeposit?.amount,
    };

    await openWalletModal();
  };

  const availableAt = computed(() => {
    if (!props.unavailable) return undefined;

    const availabilityAt = props.bonusInfo[0].availabilityAt;
    const start = Date.now();
    const end = new Date(availabilityAt).getTime();
    const startAfter = Math.ceil((end - start) / 1000);
    if (startAfter > 0) return props.bonusInfo[0].availabilityAt;
    return undefined;
  });

  const getDepositNumberContent = (depositNumber: number): string => {
    if (`${depositNumber}`.endsWith('11') || `${depositNumber}`.endsWith('12') || `${depositNumber}`.endsWith('13')) {
      return `${depositNumber}th`;
    }
    if (`${depositNumber}`.endsWith('1')) return `${depositNumber}st`;
    if (`${depositNumber}`.endsWith('2')) return `${depositNumber}nd`;
    if (`${depositNumber}`.endsWith('3')) return `${depositNumber}rd`;
    return `${depositNumber}th`;
  };

  const availableDepositLabel = computed(() => {
    if (!props.unavailable || successDepositCount.value === undefined) return undefined;
    const fromDeposit = props.bonusInfo[0].triggerConditions?.invoiceNumberFrom;
    const toDeposit = props.bonusInfo[0].triggerConditions?.invoiceNumberTo;
    if (fromDeposit && successDepositCount.value >= fromDeposit) return undefined;

    if (fromDeposit && toDeposit && fromDeposit === toDeposit) {
      const exactLabel =
        getContent(
          globalComponentsContent,
          defaultLocaleGlobalComponentsContent,
          'bonuses.depositBonus.availableExactCountLabel'
        ) || '';

      const exactLabelContent = exactLabel.replace('{number}', getDepositNumberContent(fromDeposit));
      return replaceContent(exactLabelContent, '*');
    }

    if (fromDeposit) {
      const fromLabel =
        getContent(
          globalComponentsContent,
          defaultLocaleGlobalComponentsContent,
          'bonuses.depositBonus.availableFromCountLabel'
        ) || '';

      const fromLabelContent = fromLabel.replace('{number}', getDepositNumberContent(fromDeposit));
      return replaceContent(fromLabelContent, '*');
    }

    return undefined;
  });

  const openInfo = async (): Promise<void> => {
    await openModal('deposit-bonus-detail', {
      props: {
        bonusInfo: props.bonusInfo,
        unavailable: props.unavailable,
        availableAt: availableAt.value,
        availableDepositLabel: availableDepositLabel.value,
      },
    });
  };

  onMounted(() => {
    if (props.unavailable && !availableAt.value && !availableDepositLabel.value) bonusStore.getDepositBonuses();
  });
</script>

<style src="~/assets/styles/components/card/deposit-bonus.scss" lang="scss" />
